div.Container{
    /* border: 2px solid red; */
    display: flex;
    border-bottom: 2px solid #D3D3D3;
    margin-top: 10px;
    justify-content: flex-center;
    z-index: 1;

    div.RightElements{
        /* border: 5px solid blue; */
        z-index: 1;
        /* width: max-content; */
        
        div.ItemCheckbox{
        }
    }

    div.MidElements{
        /* border: 5px solid red; */
        /* z-index: 1; */
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 5px;
        margin-right: 5px;

        div.MidUpElements {
            display: flex;

            div.MidRightElements {
                flex: 2 1;

                div.ImagFlagDetalisWrapper{
                    /* border: 1px solid blue; */
                    overflow: hidden;
                    display: flex;
                    align-items: flex-center;
        
                    div.ImageAndFlag{
                        /* border: 1px solid red; */
                        padding: 0;
                        margin: 0;
                        height: 100%;
                        max-width: 200px;
                        min-width: 100px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
        
                        .ItemImage{
                            /* border: 1px solid red; */
                            max-width:  50px;
                        }
        
                        div.ItemFlags {
                            display: flex;
                            text-align: center;
                            align-items: center;
                            justify-content: center;
                            

                            .label{
                                cursor: help;
                                /* background-color: #fff; */
                                /* color: #000; */
                                border-radius: 10px;
                                width: 18px;
                                text-align: center;
                                line-height: 18px;
                                font-size: 15px;
                                position: absolute;
                                left: 5px;
                                top: calc(50% - 9px);;
                            }
                            div.iFlags {
                                position: relative;
                                color: var(--mainWhite);
                                width: 100%;
                                max-width: 200px;
                                border-radius: 30px;
                                height: 20px;
                                font-size: 12px;
                                font-weight: 500;
                                background-color: #E10000;
                                
                                /* &.iNew {
                                    border:1px solid  #FF8000;
                                    background-color: #FF8000;
                                }
                                
                                &.iSale {
                                    border:1px solid  #E10000;
                                    background-color: #E10000;
                                }
                                
                                &.iSeason {
                                    border:1px solid  #FF8000;
                                    background-color: #FF8000;
                                } */
                            }
                        }
                    }
        
                    div.ItemDetails{
                        /* border: 1px solid red; */
                        height: 100%;
                        width: 100%;
                        min-width: 70px;
                        padding: 0;
                        margin: 0;
                        margin-right: 10px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        overflow: hidden;
                        overflow-x: auto;
                        line-height: 120%;
                        /* white-space: break-spaces; */
        
                        div.ItemName{
                            /* border: 1px solid red; */
                            font-size: 18px;
                            font-weight: 500;
                            /* overflow: hidden; */
                            /* text-overflow: ellipsis; */
                            white-space: normal;  
                            cursor: default;
                            margin:0;
                            color: var(--mainMainOrBlack);
                        }
        
                        div.ItemPrice{
                            display: flex;
                            flex-wrap: wrap;
                            gap: 5%;
                            justify-content: flex-start;
                            font-size: 18px;
                            font-weight: 500;
                            font-family: 'Heebo', sans-serif;
                            cursor: default;

                        }
        
                        div.ItemQuantity{
                            /* border: 1px solid red; */
                            font-size: 18px;
                            font-weight: 500;
                            cursor: default;
                        }
                    }
                }
            }

            div.MidLeftElements {
                flex: 1 1;
                z-index: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: 100%;
                max-width: 120px;
                justify-content: space-between;
                height: max-content;

                div.MoreInfo{
                    /* border: 1px solid red; */
                    margin-bottom: auto;
                    width: 100%;
                    /* direction : rtl; */
                    flex: 1 1;
                    padding-right: 5px;
                    
                    div.MoreInfoTooltip{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        
                        .Tooltip{
                            max-width: 300px !important;
                            text-align: start;
                            white-space: pre-wrap !important;
                        }
                    }
                    div.MoreInfoTooltip > .label{
                        cursor: help;
                        background-color: var(--mainMainOrBlack);
                        color: #fff;
                        border-radius: 10px;
                        display: inline-block;
                        width: 18px;
                        text-align: center;
                        line-height: 18px;
                        font-size: 12px;
                        color: var(--mainWhiteOrBlack);
                        margin-bottom: 10px;
                    }
                }
                
                div.Buttons{
                    /* border: 2px solid green; */
                    margin-bottom: auto;
                    flex: 1 1;
                    padding-right: 5px;
        
                    button{
                        border-radius: 25px;
                        width: 100px;
                        height: 28px;
                        font-size: 14px;
                        /* font-weight: 500; */
                        color: var(--mainWhiteOrBlack);
                        font-family: 'Heebo', sans-serif;
                        background-color: #FFFFFF;
                        outline: none;
                        cursor: pointer;
                    }
                    div.EditButton{
                        margin-bottom: 5px;

                        button {
                            background-color: var(--mainColor);
                            border: 1px solid var(--mainColor);
                        }
                    }
                    div.RemoveButton{
                        button {
                            background-color: var(--mainColor);
                            border: 1px solid var(--mainColor);
                        }
                    }
                }
            }
        }

        div.Comment{
            /* border: 1px solid blue; */
            display: flex;
            align-items: center;
            margin-top: 10px;
            width: 100%;

            div.Title{
                font-size: 13px;
                text-decoration: underline;
                cursor: pointer;
            }

            div.InputText{
                width: 100%;
                input{
                    width: 100%;
                    font-size: 13px;
                    outline: none;
                    border: none;
                    font-family: 'Heebo', sans-serif;
                }
            }
        }
    }
}

@media (max-width: 600px){
    div.Container{
        div.MidElements{
            div.MidUpElements {
                div.MidRightElements {
                    div.ImagFlagDetalisWrapper{
                        div.ImageAndFlag{

                            .ItemImage{
                                width:  60px;
                                cursor: pointer;
                            }

                            div.ItemFlags{
                                div.iFlags{
                                    height: 18px;
                                }
                            }
                        }
                        div.ItemDetails{
                            div.ItemName{
                                font-size: 14px;
                            }
                            div.ItemPrice{
                                font-size: 12px;
                            }

                            div.ItemQuantity{
                                font-size: 12px;
                            }
                        }
                    }
                }

                div.MidLeftElements {
                    div.Buttons{
                        button{
                            height: 24px;
                            width: 80px;
                        }
                    }
                }
            }
        }
    }
}